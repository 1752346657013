import { IAssurance, IAssuranceSearchOptions, IBO } from "../../Models/IAssurance";
import { REPORT_CORE_API_ENDPOINT } from "../../Constants/ApiUrls";
import axios from "axios";
import { ICountry } from "../../Models/ICountry";
import { concatUrl } from "../../Utils/Utils"

const ReservoirController = {
  createST: async (project_name: string, country: ICountry, callback?: (reportId: string) => void) => {
    axios.post(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.POST_CREATE_RESERVOIR_STUDY),
      {
        "project_name": project_name,
        'country': country
      }, { withCredentials: true }).then((response) => {
        console.log(response);
        if (callback) {
          callback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default ReservoirController;
