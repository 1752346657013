import { ModalDialog } from "@Eni/docware-fe-master";
import { IComboBox, IComboBoxProps } from "@fluentui/react/lib/ComboBox";
import { IDropdownProps } from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { IStackProps, Stack } from "@fluentui/react/lib/Stack";
import { createRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Constants/AppRoutes";
import { IAssurance, IAssuranceSearchOptions, IBO } from "../../Models/IAssurance";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import "./AssuranceSelector.scss";

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
}

enum SelectionPhase {
  AssuranceCodeSelection, Confirmation
}

export const AssuranceSelector = (props: Props) => {
  const navigate = useNavigate();
  const [phase, setPhase] = useState(SelectionPhase.AssuranceCodeSelection);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState<string | number>("");
  const [countryName, setCountryName] = useState<string | number>("");
  const [selectedYear, setSelectedYear] = useState<string | number>("");
  const [version, setVersion] = useState<string | number>("");

  const [assuranceData, setAssuranceData] = useState<IAssurance | null>(null);

  const versionInputRef = createRef<IComboBox>();
  const yearInputRef = createRef<IComboBox>();

  const countries = useSelector<GlobalState, IBO[]>(state => state.generic.countries);

  const [years, setYears] = useState<string[]>([]);
  const [versions, setVersions] = useState<string[]>([]);
  const [assuranceSearchOptions, setAssuranceSearchOptions] = useState<IAssuranceSearchOptions>(null);

  const countryDropdownProps : IComboBoxProps = {
    autoComplete: "on",
    options: countries.map(c => {
      return { key: c.id, id: c.id, text: c.name}
    }),
    selectedKey: countryId,
    onChange(event, option?, index?, value?) {
      setSelectedYear("");
      setVersion("");
      ApiService.AssuranceController.getAssuranceSearchOptions(option.id, assuranceSearchOptions => {
        setAssuranceSearchOptions(assuranceSearchOptions);
      });
      setCountryId(option.key);
      setCountryName(option.text);
      yearInputRef.current.focus();
    },
  };

  const getMatchingVersions = () => {
    let matchingYears = assuranceSearchOptions?.years.filter(year => {
      return year.year == selectedYear;
    });
  
    let availableVersions = [];
  
    if (matchingYears?.length > 0) {
      availableVersions = matchingYears[0].versions;
    }

    return availableVersions;
  };


  const yearDropdownProps : IComboBoxProps = {
    options: assuranceSearchOptions?.years.map(year => {
      return { key: year.year, id: year.year, text: year.year }
    }),
    componentRef: yearInputRef,
    selectedKey: selectedYear,
    onChange(event, option?, index?, value?) {
      setSelectedYear(option.key);
      setVersion(null);
      versionInputRef.current.focus();
    },
  };

  const versionsDropdownProps : IComboBoxProps = {
    options: getMatchingVersions().map(versions => {
      return { key: versions, id: versions, text: versions }
    }),
    componentRef: versionInputRef,
    selectedKey: version,
    onChange(event, option?, index?, value?) {
      setVersion(option.key);
    },
  };

  const getAssuranceCode = () => {
    const assuranceCode = `${countryName}-${selectedYear}-${version}`;
    return assuranceCode;
  }

  const acceptAssuranceCode = () => {
    if (countryName && version && selectedYear) {
      setLoading(true);
        ApiService.AssuranceController.getAssurance(getAssuranceCode(), assurance => {
          setLoading(false);
          setAssuranceData(assurance);
          setPhase(SelectionPhase.Confirmation);
        })
    } else {
      setShowWarning(true);
    }
  }

  const acceptConfirmation = () => {
    setLoading(true);
    ApiService.AssuranceController.createPrd(getAssuranceCode(), reportid => {
      setLoading(false);
      props.setShow(false);
      navigate(`/${AppRoutes.REPORT_PAGE}/${reportid}`);
    });
  }

  const accept = () => {
    if (phase == SelectionPhase.AssuranceCodeSelection) {
      acceptAssuranceCode();
    } else {
      acceptConfirmation();
    }
  };

  const resetAndClose = () => {
    props.setShow(false);
    setPhase(SelectionPhase.AssuranceCodeSelection);
    setCountryId(null);
    setCountryName(null);
    setSelectedYear(null);
    setVersion(null);
  };

  return (<div className="modal-wrapper"><ModalDialog
              modalTitle={phase==SelectionPhase.AssuranceCodeSelection? "Create a new PRD from existing assurance ID": "Data Summary"}
              loadingButtons={loading}
              onAbort={() => resetAndClose()}
              modalButtons={[
                { label:"Cancel", onClick: () => resetAndClose() },
                { label:"Accept", onClick: () => accept() }, 
              ]}
              enableModal={props.show} 
              modalInnerComponent={<>
                                    {phase== SelectionPhase.AssuranceCodeSelection && <div>
                                        <div><strong>Please a insert valid assurance id to create a project review document</strong></div>
                                        <br/>
                                        <div className="assurance-selector-container">
                                              <FluentUIDecorator label={"Country"} info={null}
                                                              fluentComponent={FluentUIDecoratorTypes.ComboBox(countryDropdownProps)}
                                                            />
                                              <span>&#8211;</span>
                                              <FluentUIDecorator label={"Year"} info={null}
                                                              fluentComponent={FluentUIDecoratorTypes.ComboBox(yearDropdownProps)}
                                                            />
                                              <span>&#8211;</span>
                                              <FluentUIDecorator label={"Version"} info={null}
                                                              denyComponentHardReset
                                                              fluentComponent={FluentUIDecoratorTypes.ComboBox(versionsDropdownProps)}
                                                            />
                                          </div>
                                          {showWarning && <div>
                                            <br />
                                            <MessageBar messageBarType={MessageBarType.warning} dismissButtonAriaLabel="Close">
                                                Please select country, year and version to proceed.
                                            </MessageBar>
                                          </div>}
                                      </div>}
                                      {phase == SelectionPhase.Confirmation && <div>
                                        <AssuranceView assuranceData={assuranceData}/>
                                      </div>}
                                    </>} /></div>);
}


const AssuranceView = (props: { assuranceData: IAssurance }) => {
  return  <div className="assurance-view">
            <div className="page-sub-title">ASSURANCE INFO</div>
            <div className="assurance-view pad">
              <div><strong>Assurance Code:</strong> {props.assuranceData.assuranceCode}</div>
              <div><strong>Country:</strong> {props.assuranceData.country.name}</div>
            </div>
            <div className="page-sub-title">BUSINESS OBJECTS</div>
            <div className="assurance-view-lists pad">
              <div className="assurance-view-lists-bo">
                <strong>Target(s)</strong>
                <div>
                  <br /> 
                  {props.assuranceData.targets.map(item => {
                    return <div key={item.id}>{item.name}</div>
                  })}
                </div>
              </div>
              <div className="assurance-view-lists-bo">
                <strong>Play(s)</strong>
                <div> 
                <br />
                {props.assuranceData.plays.map(item => {
                  return <div key={item.id}>{item.name}</div>
                })}
              </div>
                </div>
              <div className="assurance-view-lists-bo">
                <strong>Prospect(s)</strong>
                <div>
                <br /> 
                {props.assuranceData.prospects.map(item => {
                  return <div key={item.id}>{item.name}</div>
                })}
                </div>
              </div>
            </div>
          </div>
};