import { AIAssistant } from "../../Components/AIAssistant/AIAssistant";
import { ReportEditorBar as ReportEditorBar } from "../../Components/ReportEditorBar/ReportEditorBar";
import { ReportContent } from "../../Components/ReportContent/ReportContent";
import { ReportIndex } from "../../Components/ReportIndex/ReportIndex";
import "./ReportPage.scss";
import { useEffect } from "react";
import ApiService from "../../Services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { ReportEditorAction } from "../../Reducers/ReportEditor/ReportEditorAction";
import { IReportData } from "../../Models/IDocumentChapter";
import { GlobalState } from "../../Reducers/RootReducer";
import { useParams } from "react-router-dom";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { NavbarState } from "../../Reducers/Generic/GenericState";
import { ChapterAssigner } from "../../Components/ChapterAssigner/ChapterAssigner";

const ReportPage = () => {
  const dispatch = useDispatch(); 
  const reportData: IReportData = useSelector<GlobalState, IReportData>(state => state.reportEditor.reportData);
  
  useEffect(() => {
    dispatch(GenericActions.setNavbarStateAction(NavbarState.Report));
  }, []);

  let { id } = useParams();

  useEffect(() => {
    ApiService.ReportController.getReport(id, reportData => {
      dispatch(ReportEditorAction.setReportDataActionAction(reportData));
    });
  }, []);

  return (
    <div>
      <ReportEditorBar reportData={reportData} />
      <div className="document-editor-container">
        <ReportIndex />
        <ReportContent />
        <AIAssistant />
      </div>
      <ChapterAssigner />
    </div>
  );
};

export default ReportPage;
