import { DocumentManagementToolbar } from "@Eni/docware-fe-master";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Constants/AppRoutes";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { NavbarState } from "../../Reducers/Generic/GenericState";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";

export interface ISampleDocumentManagementToolbarProps {
  type: string;
  fileType?: "main" | "signature" | "attachment";
  customLayout?: any[];
  onExitAction?: () => void;
}


export const AppToolbar = (
  props: ISampleDocumentManagementToolbarProps
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const state: NavbarState = useSelector<GlobalState, NavbarState>(state => state.generic.navbarState);
  const selectedFiles: string[] = useSelector<GlobalState, string[]>(state => state.generic.selectedFiles);


  const getHomeNavbar = () => {
    
    const menuLayouts = {
      name: "navigation-voices",
      content: {
        leftButtons: [
          {
            text: "Create PRD",
            iconName: "TextDocumentShared",
            onClick: () => {
              dispatch(GenericActions.setShowAssuranceSelectorAction(true));
            },
          },
          {
            text: "Create IRS",
            iconName: "TextDocumentShared",
            onClick: () => {
              dispatch(GenericActions.setShowReservoirCreatorAction(true));
            },
          },
          {
            text: "Create Well Result",
            iconName: "ReportDocument",
            onClick: () => {
  
            },
          }
        ],
        rightButtons: [],
      },
    };
  
    return menuLayouts;
  }
  
  const getReportNavbar = () => {    
    const menuLayouts = {
      name: "navigation-voices",
      content: {
        leftButtons: [
          {
            text: "Home",
            iconName: "Home",
            onClick: () => {
              navigate(AppRoutes.BASE_ROUTE);
            },
          }
        ],
        rightButtons: [],
      },
    };
  
    return menuLayouts;
  }
  
  const getSelectedFilesNavbar = () => {
  
    let menuLayout = null;
  
    const deleteFiles = () => {
      ApiService.ReportListController.deleteFiles(selectedFiles, () => {
        window.document.dispatchEvent(
          new CustomEvent('api-toast-result', {
            detail: {
              text: selectedFiles.length > 1 ? 'Files deleted successfully' : 'File deleted successfully',
              type: 'success',
            },
          })
        );
        dispatch(GenericActions.setSelectedFilesAction({
        navbarState: NavbarState.Home,
        selectedFiles: []
      }));
      });
      
    }
  
    if (selectedFiles.length == 1) {
      menuLayout = {
        name: "navigation-voices",
        content: {
          leftButtons: [
            {
              text: "Open",
              iconName: "Page",
              onClick: () => {
                navigate(`/${AppRoutes.REPORT_PAGE}/${selectedFiles[0]}`);
              },
            },
            {
              text: "Upload to Drive",
              iconName: "Upload",
              onClick: () => {
                console.log("HIIIIIIIIIIII")
              },
            },
            {
              text: "Move to Trash",
              iconName: "RecycleBin",
              onClick: () => {
                deleteFiles();
              },
            }
          ],
          rightButtons: [],
        },
      };
    } else {
      menuLayout = {
        name: "navigation-voices",
        content: {
          leftButtons: [
            {
              text: "Move to Trash",
              iconName: "RecycleBin",
              onClick: () => {
                deleteFiles();
              },
            }
          ],
          rightButtons: [],
        },
      };
    }
  
    return menuLayout;
  }

  let layout = null;

  switch (state) {
    case NavbarState.SelectedFiles:
      layout = getSelectedFilesNavbar();
      break;
    case NavbarState.Report:
      layout = getReportNavbar();
      break;
    case NavbarState.Home:
      layout = getHomeNavbar();
    default:
      break;
  }

  return (
    <div>
      <DocumentManagementToolbar loading={loading} layout={layout} />
    </div>
  );
};

