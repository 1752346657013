import { IBO } from "../../Models/IAssurance";
import { NavbarState } from "./GenericState";

export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_SHOW_ASSURANCE_SELECTOR = "SET_SHOW_ASSURANCE_SELECTOR";
export const SET_NAVBAR_STATE = "SET_NAVBAR_STATE";
export const SET_SELECTED_FILES = "SET_SELECTED_FILES";
export const SET_SHOW_RESERVOIR_CREATION = "SET_SHOW_RESERVOIR_CREATION"

interface SetCountriesAction {
  type: typeof SET_COUNTRIES;
  payload: IBO[];
}

interface SetNavbarStateAction {
  type: typeof SET_NAVBAR_STATE;
  payload: NavbarState;
}

interface SetSelectedFilesAction {
  type: typeof SET_SELECTED_FILES;
  payload: { navbarState: NavbarState, selectedFiles: string[]};
}

interface SetShowAssuranceSelectorAction {
  type: typeof SET_SHOW_ASSURANCE_SELECTOR;
  payload: boolean;
}
interface SetShowReservoirCreationAction {
  type: typeof SET_SHOW_RESERVOIR_CREATION;
  payload: boolean;
}

export type GenericActionType = SetCountriesAction | SetShowAssuranceSelectorAction | SetNavbarStateAction | SetSelectedFilesAction | SetShowReservoirCreationAction;

const setCountriesAction = (data: IBO[]): SetCountriesAction => {
  return { type: SET_COUNTRIES, payload: data };
};


const setNavbarStateAction = (data: NavbarState): SetNavbarStateAction => {
  return { type: SET_NAVBAR_STATE, payload: data };
};

const setSelectedFilesAction = (data: { navbarState: NavbarState, selectedFiles: string[]}): SetSelectedFilesAction => {
  return { type: SET_SELECTED_FILES, payload: data };
};

const setShowAssuranceSelectorAction = (data: boolean): SetShowAssuranceSelectorAction => {
  return { type: SET_SHOW_ASSURANCE_SELECTOR, payload: data };
};

const setShowReservoirCreatorAction = (data: boolean): SetShowReservoirCreationAction => {
  return { type: SET_SHOW_RESERVOIR_CREATION, payload: data };
};

export const GenericActions = {
  setCountriesAction,
  setShowAssuranceSelectorAction,
  setSelectedFilesAction,
  setNavbarStateAction,
  setShowReservoirCreatorAction
};
