import AssuranceController from "./Controllers/AssuranceController";
import ReportController from "./Controllers/ReportController";
import ReportListController from "./Controllers/ReportListController";
import ReservoirController from "./Controllers/ReservoirController";
import UserController from "./Controllers/UserController";

const ApiService = {
  AssuranceController,
  ReportListController,
  ReportController,
  UserController,
  ReservoirController,
};

export default ApiService;
