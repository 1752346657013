import AppRoutes from "./Constants/AppRoutes";
import FirstSamplePage from "./Pages/FirstSamplePage/FirstSamplePage";
import DesignSystem from "./Pages/DesignSystem/DesignSystem";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
import HomePage from "./Pages/HomePage/HomePage";
import ReportPage from "./Pages/ReportPage/ReportPage";
import ReservoirPage from "./Pages/ReservoirPage/ReservoirPage";

export interface IAppRoute {
  path: string;
  element: JSX.Element;
}

const AppRoutesMap: IAppRoute[] = [
  {
    path: AppRoutes.BASE_ROUTE,
    element: <HomePage />,
  },
  {
    path: AppRoutes.HOME_PAGE,
    element: <HomePage />,
  },
  {
    path: `${AppRoutes.REPORT_PAGE}/:id`,
    element: <ReportPage />,
  },
  {
    path: `${AppRoutes.RESERVOIR_PAGE}/:id`,
    element: <ReservoirPage />,
  },
  {
    path: AppRoutes.DASHBOARD_PAGE,
    element: <DesignSystem />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default AppRoutesMap;
