import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-page-wrap">
      <div className="not-found-page-title">
        The requested page does not exists or has been moved.
      </div>
      <div className="not-found-page-number">404</div>
    </div>
  );
};

export default NotFoundPage;
