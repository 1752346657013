import { ModalDialog } from "@Eni/docware-fe-master";
import { IPersona, IPersonaProps } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { idText } from "typescript";
import { IDocumentChapter, IDocwareUser } from "../../Models/IDocumentChapter";
import { ReportEditorAction } from "../../Reducers/ReportEditor/ReportEditorAction";
import { PeoplePickerModal } from "../../Reducers/ReportEditor/ReportEditorState";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { PeoplePickerProps } from "../PeoplePicker/PeoplePicker";
import "./ChapterAssigner.scss";
// TODO MR CR-12-2022

export const ChapterAssigner = () => {
  const chapter = useSelector<GlobalState, IDocumentChapter>(state => state.reportEditor.peoplePickerModal.chapter);
  const peoplePickerModal = useSelector<GlobalState, PeoplePickerModal>(state => state.reportEditor.peoplePickerModal);
  const [personas, setPersonas] = useState<IPersonaProps[]>([]);
  const dispatch = useDispatch(); 

  useEffect(() => {
    const chapterUsers = chapter?.users?.map(user => {
      return {
        key: user.id,
        text: user.fullName
      }
    });
    setPersonas(chapterUsers);
  }, [chapter]);

  const peoplePickerProps : PeoplePickerProps = {
    label: `Assign people to '${chapter?.title || ""}' chapter`,
    selectedUsers: personas,
    onPeopleChanged(updatedPeople) {
      setPersonas(updatedPeople);
    },
    onFilterChanged(filterText, currentPersonas, limitResults) {
      return new Promise<IPersonaProps[]>((resolve, reject) => {
        ApiService.UserController.getUsers(filterText, (users) => {
          const personaProps = users?.map(user => {
            return {
              key: user.id,
              text: user.fullName
            }
          });
          resolve(personaProps);
        })
      });
    },
  }

  const save = () => {
    let chapterToSave = {
      ...chapter,
      users: personas.map(persona => {
        const user : IDocwareUser = {
          id: persona.key.toString(), fullName: persona.text
        };
        return user;
      })
    };
    ApiService.ReportController.saveChapter(chapterToSave, () => {
      window.document.dispatchEvent(
        new CustomEvent('api-toast-result', {
          detail: {
            text: 'User added successfully',
            type: 'success',
          },
        })
      );
    });
    dispatch(ReportEditorAction.setChapterUnderEditAction(null));
    dispatch(ReportEditorAction.setSavedChapterAction(chapterToSave));
  };

  
  const resetAndClose = () => dispatch(ReportEditorAction.setShowPeoplePickerModal({ show: false, chapter: null }));
  const accept = () => {
    save();
    dispatch(ReportEditorAction.setShowPeoplePickerModal({ show: false, chapter: null }));
  }


  return (<ModalDialog
              modalTitle={"Assign Chapter"}
              // loadingButtons={loading}
              onAbort={resetAndClose}
              modalButtons={[
                { label:"Cancel", onClick: () => resetAndClose() },
                { label:"Accept", onClick: () => accept() }, 
              ]}
              enableModal={peoplePickerModal.show} 
              modalInnerComponent={<div>
                                    <FluentUIDecorator label={null} info={null}
                                        fluentComponent={FluentUIDecoratorTypes.PeoplePicker(peoplePickerProps)} />
                                  </div>}/>) 
}