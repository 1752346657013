import { useDispatch, useSelector, useStore } from "react-redux";
import { IDocumentChapter, IReportData } from "../../Models/IDocumentChapter";
import { GlobalState } from "../../Reducers/RootReducer";
import "./ReportContent.scss";
import logo from "../../Images/logo_eni.png";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { ReportEditorAction } from "../../Reducers/ReportEditor/ReportEditorAction";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useRefEffect } from "@fluentui/react-hooks";
import ApiService from "../../Services/ApiService";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import { PeoplePickerProps } from "../PeoplePicker/PeoplePicker";
import { IPersonaProps, PersonaSize } from "@fluentui/react/lib/Persona";
import { Facepile, IFacepilePersona, OverflowButtonType } from "@fluentui/react/lib/Facepile";

export const ReportContent = (props: {}) => {
  const dispatch = useDispatch();
  const chapterIdToScrollTo = useSelector<GlobalState, string>(state => state.reportEditor.chapterToScroll);
  const reportData: IReportData = useSelector<GlobalState, IReportData>(state => state.reportEditor.reportData);

  const reportHeaderTitle = () => {
    switch (reportData?.info.reportType) {
      case "PRD":
        return "TAR - EAR project review document"
      case "RS":
        return "IRS - Integrated Reservoir Study"
      default:
        return null;
    }
  };

  useEffect(() => {
    const resetScrollId = () => {
      dispatch(ReportEditorAction.setEditorScrollAction(null))
    };
    const contentPage = document.getElementById('content-page');
    contentPage.addEventListener("scroll", resetScrollId);

    return () => {
      contentPage.removeEventListener("scroll", resetScrollId);
    }
  }, [])

  useEffect(() => {
    const offsetFromTopOfThePage = 312;
    if (chapterIdToScrollTo) {
      var chapterElement = document.getElementById(chapterIdToScrollTo);
      if (chapterElement) {
        var topPosition = chapterElement.offsetTop - offsetFromTopOfThePage;
        document.getElementById('content-page').scrollTop = topPosition;
      }
    }
  }, [chapterIdToScrollTo])

  return (
    <div className="report-editor-section content-section">
      <div className="report-header">
        <img src={logo} className="header-image" />
        <div className="header-title">{reportHeaderTitle()}</div>
      </div>
      <div className="content-page" id="content-page">
        {(reportData?.chapters || []).map(chapter => <ChapterItem key={chapter.id} chapter={chapter} />)}
      </div>
    </div>
  );
}

const ChapterItem = (props: { chapter: IDocumentChapter }) => {
  const dispatch = useDispatch();
  const chapterUnderEdit = useSelector<GlobalState, IDocumentChapter>(state => state.reportEditor.chapterUnderEdit);
  const content = useSelector<GlobalState, string>(state => state.reportEditor.editrContent);
  const businessObjectsList = useSelector<GlobalState, string[]>(state => state.reportEditor.businessObjectsList);
  const [showEditor, setShowEditor] = useState(false);

  const setContent = (value: string) => {
    dispatch(ReportEditorAction.setEditorContentAction(value));
  }

  const setBoList = (value: string[]) => {
    dispatch(ReportEditorAction.setBusinessObjectListAction(value));
  }

  const openEditor = () => {
    dispatch(ReportEditorAction.setChapterUnderEditAction(props.chapter));
    setContent(props.chapter.content);
    setBoList([])
  };

  const discard = () => {
    dispatch(ReportEditorAction.setChapterUnderEditAction(null));
    setContent("");
    setBoList([])
  };

  useEffect(() => {
    if (!content) {
      return;
    }

    const regex = />#((\w)+)</gm;
    const match = regex.exec(content);
    if (match && match.length > 1) {
      let boText = match[1];
      console.log(boText);
      dispatch(ReportEditorAction.setSuggestionSearchAction(boText));
    }

  }, [content])
  
  const save = () => {
    let chapterToSave = {
      ...props.chapter,
      content: content
    };
    ApiService.ReportController.saveChapter(chapterToSave, () => {
      window.document.dispatchEvent(
        new CustomEvent('api-toast-result', {
          detail: {
            text: 'Chapter saved successfully',
            type: 'success',
          },
        })
      );
    });
    if(businessObjectsList.length){
        ApiService.ReportController.sendClickedBoIds(businessObjectsList, ()=>{
        setBoList([])
      })
    }
    
    dispatch(ReportEditorAction.setChapterUnderEditAction(null));
    dispatch(ReportEditorAction.setSavedChapterAction(chapterToSave));
    setContent("");
  };

  useEffect(() => {
    setShowEditor(props.chapter?.id == chapterUnderEdit?.id)
  }, [chapterUnderEdit])


  let modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
  * Quill editor formats
  * See https://quilljs.com/docs/formats/
  */
  let formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'color', 'background'
  ]

  console.log("FIIIIIIX!")
  const personas: IFacepilePersona[] = (props?.chapter?.users || []).map(user => {
    return {
      personaName: user.fullName
    }
  });

  const buttonProps = { key: OverflowButtonType.descriptive, text: null };

  const addButtonProps = {
    ariaLabel: 'Assign new people',
    onClick: (ev: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(ReportEditorAction.setShowPeoplePickerModal({ show: true, chapter: props.chapter }));
    },
  };

  return <div id={props.chapter.id}>
    <div>
      <div className={`chapter-item chapter-level-${props.chapter.level}`}>
        <span>{props.chapter.prefix} {props.chapter.title}</span>
        {(showEditor || personas.length>0) &&<Facepile
                    personaSize={PersonaSize.size24}
                    personas={personas}
                    maxDisplayablePersonas={5}
                    overflowButtonType={OverflowButtonType.descriptive}
                    showAddButton = {showEditor}
                    overflowButtonProps={buttonProps}
                    addButtonProps={addButtonProps}
                    ariaLabel="People working on chapter"
                  />}
      </div>
      <div className="chapter-content">
        {showEditor && <>
          <ReactQuill theme="snow" value={content} onChange={setContent} formats={formats} modules={modules} />
          
          <div className="editor-buttons">
            <DefaultButton text="Discard" onClick={() => discard()} />
            <PrimaryButton text="Save" onClick={() => save()} />
          </div>
        </>}
        {!showEditor && <div className="chapter-content-text" onClick={openEditor} dangerouslySetInnerHTML={{ __html: props.chapter.content }} />}
      </div>
    </div>
    <div>
      {(props?.chapter?.subChapters || []).map(chapter => <ChapterItem key={`chapter_${props.chapter.id}`} chapter={chapter} />)}
    </div>
  </div>
}
