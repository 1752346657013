import { AuthenticationHelper, UserMenu } from "@Eni/docware-fe-master";
import { Icon } from "@fluentui/react/lib/Icon";
import { IPersonaSharedProps, Persona, PersonaPresence, PersonaSize } from "@fluentui/react/lib/Persona";
import { useSelector } from "react-redux";
import { IBO } from "../../Models/IAssurance";
import { IDocwareUser } from "../../Models/IDocumentChapter";
import { GlobalState } from "../../Reducers/RootReducer";
import "./SampleUserMenu.scss";

// const SampleUserMenu = () => {
//   return (
//     <div>
//       {/* TODO MR come mai non mi esce lo user? */}
//       <UserMenu
//         content={<div>place here your app specific user menu content</div>}
//       />
//     </div>
//   );
// };

// export default SampleUserMenu;



const SampleUserMenu = () => {
  const countries = useSelector<GlobalState, IBO[]>(state => state.generic.countries);
  const userInfo = JSON.parse(localStorage.getItem("logged-user") || "{}");
  let initials = "";
  let fullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const hasFirstName = userInfo.firstName && userInfo.firstName.length > 0;
  const hasLastName = userInfo.lastName &&  userInfo.lastName.length > 0
  if (hasFirstName && hasLastName) {
    initials = `${userInfo.firstName[0]}${userInfo.lastName[0]}`
  }

  const examplePersona: IPersonaSharedProps = {
    imageInitials: initials,
    text: fullName,
    secondaryText: '', //'Software Engineer',
  };

  let menuContent = (
    <div>
      <Persona
        {...examplePersona}
        size={PersonaSize.size48}
        hidePersonaDetails={false}
        presence={PersonaPresence.online}
      />
      <div className="user-menu-under-zone">
      <div className="user-menu-under-zone-block">
        <div className="user-menu-under-zone-title">Exp BMT Countries</div>
          <nav className="user-menu-under-zone-content">
            {countries
              ?.map((x: any) => {
                return x.name;
              })
              .join(', ')}
          </nav>
        </div>
        <div className="user-menu-under-zone-block">
          <div
            className="user-menu-under-zone-block-icon"
            onClick={() => {
                AuthenticationHelper.logout();
            }}>
            <Icon style={{fontSize: '1em'}} iconName={"ErrorBadge"} />;
            <div style={{marginRight: '0.5em'}}></div>
            <div>Logout</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <UserMenu content={menuContent} />
    </div>
  );
};

export default SampleUserMenu;