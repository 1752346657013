import { combineReducers } from "redux";
import { IUser } from "../Models/IUser";
import { reportEditorReducer } from "./ReportEditor/ReportEditorReducer";
import { ReportEditorState } from "./ReportEditor/ReportEditorState";
import { genericReducer } from "./Generic/GenericReducer";
import { GenericState } from "./Generic/GenericState";
import { userReducer } from "./User/UserReducer";
import { UserState } from "./User/UserState";

const rootReducer = combineReducers({
  user: userReducer,
  generic: genericReducer,
  reportEditor: reportEditorReducer
});

export interface GlobalState {
  user: UserState;
  generic: GenericState;
  reportEditor: ReportEditorState;
}

export default rootReducer;
