import axios from "axios";
import { IReportItem } from "../../Models/IReportItem";
import { REPORT_CORE_API_ENDPOINT } from "../../Constants/ApiUrls";
import { concatUrl, formatDate } from "../../Utils/Utils";

const ReportListController = {
  getReportList: async (callback?: (response: IReportItem[]) => void) => {
    axios.get(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_REPORTS),
      { withCredentials: true }).then((response) => {
        console.log(response);
        const res: IReportItem[] = response.data.map(r => {
          return {
            _id: r.id,
            reportType: r.report_type,
            countryName: r.country.name,
            assuranceCode: r.assurance_code,
            owner: r.owner.fullname,
            lastModified: formatDate(r.last_modified),
            status: r.status
          } as IReportItem
        })

        if (callback) {
          callback(res);
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
      });

  },
  deleteFiles: async (ids: string[], callback?: () => void) => {
    axios.post(concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.DELETE_REPORT),
      {
        "ids": ids
      }, { withCredentials: true }).then((response) => {
        if (callback) {
          callback();
        }
      }).catch((error) => {
        console.log(error)
      })

  }
};

export default ReportListController;
