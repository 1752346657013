import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import ApiService from "../../Services/ApiService";

export const Bootstrapper = (props: {children: any}) => {
   
    return <>{props.children}</>
}

