import { Icon } from "@fluentui/react/lib/Icon";
import "./StatusButton.scss";

export const StatusLabel = (props: { status: string }) => {
    let iconName = "Emoji";
    switch (props.status) {
        case "Draft":
            iconName = "PageEdit";
            break;
        case "Signed":
            iconName = "PenWorkspace";
            break;
    }

    return (
        <div
          className={`status-label status-label-${props.status.toLocaleLowerCase()}`}>
          <Icon iconName={iconName} />&nbsp;{" "+props.status}
        </div>
      );
}