export const concatUrl = (url1: string, url2: string) => {
    let finalUrl = url1;
    if (url1.endsWith('/')) {
      finalUrl = url1.substring(0, url1.length - 1);
    }
  
    if (url2.startsWith('/')) {
      finalUrl = finalUrl + url2;
    }else{
      finalUrl = finalUrl + '/' + url2;
    }
  
    return finalUrl;
  };

export const formatDate = (date: string) =>{
  if (date && date!= " ")
    return new Date(date)?.toISOString()?.split("T")[0]
  return " "
}