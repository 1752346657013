import "./DocumentButton.scss";
import doc_image from "../../Images/prd_doc_shape.png";

interface Props {
  title: string;
  subtitle: string;
  onClick?: () => void;
}

export const DocumentButton = (props: Props) => {
  const onCLick = () => {
    if (props.onClick) props.onClick();
  }

  return (
      <div className="cell-wrapper"  onClick={onCLick}>

          <div className="button-wrapper">
                      <div className="button-shape">
                          <div className="div-column1">
                              <div className="div-column-sub1"></div>
                              <div className="div-column-sub2"></div>
                          </div>
                          <div className="div-column2"></div>
                      </div>

              <div className="document-icon">
                  <img src={doc_image} alt="" className="image-style" />
              </div>
          </div>

          <h5 className="align-left-new-doc-title">{props.title}</h5>
          <h5 className="align-left-new-doc-sub-title">{props.subtitle}</h5>
      </div>
  )
}
