import {
  GenericActionType,
  SET_COUNTRIES,
  SET_NAVBAR_STATE,
  SET_SELECTED_FILES,
  SET_SHOW_ASSURANCE_SELECTOR,
  SET_SHOW_RESERVOIR_CREATION
} from "./GenericAction";
import { GenericState, NavbarState } from "./GenericState";

const DEFAULT_STATE: GenericState = {
  countries: [],
  showAssuranceSelector: false,
  showReservoirCreation: false,
  navbarState: NavbarState.Home,
  selectedFiles: []
};

export const genericReducer = (
  state: GenericState = DEFAULT_STATE,
  action: GenericActionType
): GenericState => {
  switch (action.type) {
    case SET_NAVBAR_STATE:
      return {
        ...state,
        navbarState: action.payload,
      };
    case SET_SELECTED_FILES:
      return {
        ...state,
        selectedFiles: action.payload.selectedFiles,
        navbarState: action.payload.navbarState
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_SHOW_ASSURANCE_SELECTOR:
      return {
        ...state,
        showAssuranceSelector: action.payload,
      };
    case SET_SHOW_RESERVOIR_CREATION:
      return {
        ...state,
        showReservoirCreation: action.payload,
      };
    default:
      return state;
  }
};
