import { report } from "process";
import { renderIntoDocument } from "react-dom/test-utils";
import { useDispatch, useSelector } from "react-redux";
import { IDocumentChapter, IReportData } from "../../Models/IDocumentChapter";
import { ReportEditorAction } from "../../Reducers/ReportEditor/ReportEditorAction";
import { GlobalState } from "../../Reducers/RootReducer";
import "./ReportIndex.scss";

export const ReportIndex = (props: {  }) => {
    const reportData: IReportData = useSelector<GlobalState, IReportData>(state => state.reportEditor.reportData);

    return (
        <div className="report-editor-section index-section">
          <div className="toc-header">Index</div>
          <div className="toc">
            {(reportData?.chapters || []).map(chapter => <IndexItem key={chapter.id} chapter={chapter} />)}
          </div>
        </div>
      );
}

const IndexItem = (props: { chapter: IDocumentChapter}) => {
  const dispatch = useDispatch();
  
  const onChapterClick = () => {
    dispatch(ReportEditorAction.setEditorScrollAction(props.chapter.id));
  };

  const padding = {
    paddingLeft: `${props.chapter.level * 10}px`
  };

  return <div>
            <div onClick={onChapterClick} className={`index-item index-level-${props.chapter.level}`} style={padding}>
              <a>{props.chapter.prefix} {props.chapter.title}</a>
            </div>
            <div>
              {(props?.chapter?.subChapters || []).map(chapter => <IndexItem key={props.chapter.id} chapter={chapter} />)}
            </div>
        </div>
}