import { DefaultButton, Icon, Rating, RatingSize, SearchBox, Suggestions } from "@fluentui/react";
import "./AIAssistant.scss";
import aiAssistantImage from "../../Images/ai-assistant.png";
import { IBO } from "../../Models/IAssurance";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { useEffect, useState } from "react";
import { useRefEffect } from "@fluentui/react-hooks";
import { ITextSuggestion } from "../../Models/ITextSuggestion";
import ApiService from "../../Services/ApiService";
import { ReportEditorAction } from "../../Reducers/ReportEditor/ReportEditorAction";
import { IBoSuggestion } from "../../Models/IBoSuggestion";
import aiLoader from "../../Images/ai-loader.gif";
import { IDocumentChapter, IReportData } from "../../Models/IDocumentChapter";
import { formatDate } from "../../Utils/Utils";
import { getBoTitleAndSection } from "../../Services/Controllers/ReportController";
import { IChatbotContext } from "../../Models/IChatbotContext";

enum AiLoaderState {
  grey, animated
}

export const AIAssistant = (props: {}) => {
  const dispatch = useDispatch();
  const chapterUnderEdit = useSelector<GlobalState, IDocumentChapter>(state => state.reportEditor.chapterUnderEdit);
  const boList: IBoSuggestion[] = useSelector<GlobalState, IBoSuggestion[]>(state => state.reportEditor.boSuggestions);
  const search: string = useSelector<GlobalState, string>(state => state.reportEditor.suggestionSearch);
  const reportUnderEdit = useSelector<GlobalState, IReportData>(state => state.reportEditor.reportData)
  const [aiLoaderState, setAiLoaderState] = useState<AiLoaderState>(AiLoaderState.grey);
  const [suggestions, setSuggestions] = useState<ITextSuggestion[]>([]);
  const [matchingBo, setMatchingBo] = useState<IBoSuggestion[]>([]);

  const setSearch = (value: string) => {
    setAiLoaderState(AiLoaderState.grey);
    dispatch(ReportEditorAction.setSuggestionSearchAction(value));
    setSuggestions([])
  }

  useEffect(() => {
    ApiService.ReportController.getBoListByChapter(chapterUnderEdit, reportUnderEdit, boList => {
      dispatch(ReportEditorAction.setBoListAction(boList));
    });
    
    if (chapterUnderEdit) {
      const titleAndSection = getBoTitleAndSection(chapterUnderEdit, reportUnderEdit);
      const context: IChatbotContext = {
        country: reportUnderEdit.info.countryName,
        title: titleAndSection.title,
        section: titleAndSection.section
      }
    }
  }, [chapterUnderEdit]);

  useEffect(()=>{
    let filteredBo = boList.filter(bo => {
      if (!chapterUnderEdit) return false;
      if (!search || search.length < 3) return false;
      return bo.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;});
    /*if(suggestions.length > 0){
      setMatchingBo([])
    }else{

    }*/
    setMatchingBo(filteredBo)
    }, [search, suggestions])

  const selectBo = (bo: IBoSuggestion) => {
    setSearch("");
    setAiLoaderState(AiLoaderState.animated);
    ApiService.ReportController.getBoSuggestions(chapterUnderEdit, reportUnderEdit, bo, suggestions => {
      setAiLoaderState(AiLoaderState.grey);
      setSuggestions(suggestions);
    });
    dispatch(ReportEditorAction.setBoHighlightAction(bo));
  };


  const selectSuggestion = (suggestion: ITextSuggestion) => {
    setSearch("");
    setSuggestions([]);
    setAiLoaderState(AiLoaderState.grey);
    dispatch(ReportEditorAction.setTextSuggestionAction(suggestion));
  };

  const showAiLoader = matchingBo.length == 0 && suggestions.length == 0;
  const showBoList = matchingBo.length > 0;
  const showSuggestionList = suggestions.length > 0;
  return (
    <div className="ai-section">
      <div className="ai-assistant-header">
        <Icon iconName="WebComponents" />
        Document actions
      </div>
      <div className="ai-assistant-content">

        <div className="ai-assistant-info-card">
          <UserAction title="Created By" user={reportUnderEdit?.info?.owner} date={formatDate(reportUnderEdit?.info?.creationDate)} />
          <UserAction title="Updated By" user={reportUnderEdit?.info?.lastModifiedBy} date={formatDate(reportUnderEdit?.info?.lastModified)} />
        </div>

        <div className="suggestion-helper">

          <div className="header">
            <div className="img-container">
              <img src={aiAssistantImage} alt="" className="image-style" />
            </div>
            <span>Ai Assistant</span>
          </div>
          <div className="suggestion-container">
            <SearchBox placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} onClear={e=>setSearch("")} />
          </div>
          {showAiLoader && <AiLoader state={aiLoaderState} />}
          {showBoList && <div className="bo-list">
            {matchingBo.map(bo => {
              return <BOCard bo={bo} onClick={() => selectBo(bo)} />
            })}
          </div>}
          {showSuggestionList && <div>
            {suggestions.map(suggestion => {
              return <SuggestionsCard suggestion={suggestion}
                onInsertClick={() => selectSuggestion(suggestion)} />
            })}
          </div>}
        </div>
      </div>
    </div>
  );
}

const AiLoader = (props: { state: AiLoaderState }) => {
  switch (props.state) {
    case AiLoaderState.animated:
      return <img src={aiLoader} alt="" className="image-style ai-loader-gif" />;
    case AiLoaderState.grey:
    default:
      return (<div className="suggestion-message">Start typing to see suggestions</div>);
  }
};

const UserAction = (props: { title: string, user: string, date: string }) => {
  return <div className="user-action-card">
    <div className="title">{props.title}</div>
    <div className="user">{props.user}</div>
    <div className="date">{formatDate(props.date)}</div>
  </div>
};

const BOCard = (props: { bo: IBoSuggestion, onClick: () => void }) => {
  return <div className="bo-card" onClick={() => props.onClick()}>
    <span>{props.bo.name}</span>
    <span className="bo-type">{props.bo.type}</span>
  </div>
};

const SuggestionsCard = (props: { suggestion: ITextSuggestion, onInsertClick: () => void }) => {
  return <div className="suggestion-card">
    <div className="bo-name">{props.suggestion.boName}</div>
    <div className="content">{props.suggestion.content}</div>
    <div className="bo-data">
      <div className="container-user">
        <div className="user" title={props.suggestion.user}>{props.suggestion.user}</div>
        <div className="date">{formatDate(props.suggestion.date)}</div>
      </div>
      <div className="container-scoring">
        <Rating
          min={1}
          max={5}
          size={RatingSize.Small}
          readOnly
          rating={props.suggestion.stars}
        />
        <div>Scoring: {props.suggestion.score}</div>
      </div>
    </div>
    <div className="divider"></div>
    <DefaultButton text="Insert" iconProps={{ iconName: 'Back' }} onClick={() => props.onInsertClick()} />
  </div>
};