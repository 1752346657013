import { ModalDialog } from "@Eni/docware-fe-master";
import { IComboBox, IComboBoxProps } from "@fluentui/react/lib/ComboBox";
import { IDropdownProps } from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { IStackProps, Stack } from "@fluentui/react/lib/Stack";
import { createRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../Constants/AppRoutes";
import { IAssurance, IAssuranceSearchOptions, IBO } from "../../Models/IAssurance";
import { GlobalState } from "../../Reducers/RootReducer";
import ApiService from "../../Services/ApiService";
import { FluentUIDecorator, FluentUIDecoratorTypes } from "../FluentUIDecorator/FluentUIDecorator";
import "./ReservoirCreator.scss";
import { ITextField, ITextFieldProps } from "@fluentui/react";
import { ICountry } from "../../Models/ICountry";

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
}


export const ReservoirCreator = (props: Props) => {
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState<string | number>("");
  const [countryName, setCountryName] = useState<string | number>("");
  const [reservoirStudyName, setReservoirStudyName] = useState<string>("");
  const countries = useSelector<GlobalState, IBO[]>(state => state.generic.countries);
  const getAssuranceCode = () => {
    const assuranceCode = `${reservoirStudyName}`;
    return assuranceCode;
  }

  const countryDropdownProps : IComboBoxProps = {
    autoComplete: "on",
    options: countries.map(c => {
      return { key: c.id, id: c.id, text: c.name}
    }),
    selectedKey: countryId,
    onChange(event, option?, index?, value?) {
      setCountryId(option.key);
      setCountryName(option.text);
    },
  };

  const textFieldProps: ITextFieldProps = {
    value: reservoirStudyName,
    onChange(event, newValue) {
      setReservoirStudyName(newValue)
    } 
}

  const accept = () => {
    if (reservoirStudyName && countryName && countryId) {
      setLoading(true);
      console.log("Name:", reservoirStudyName, "Country:", countryName)
      ApiService.ReservoirController.createST(reservoirStudyName as string, {'id': countryId, "name": countryName} as ICountry, reportid => {
        setLoading(false);
        props.setShow(false);
        navigate(`/${AppRoutes.RESERVOIR_PAGE}/${reportid}`);
      });
    } else {
      setShowWarning(true);
    }
  }

 
  const resetAndClose = () => {
    props.setShow(false);
    setReservoirStudyName(null);
    setCountryId(null);
    setCountryName(null);
    setLoading(false);
  };

  return (<div className="modal-wrapper"><ModalDialog
              modalTitle={"Create a new IRS Document"}
              loadingButtons={loading}
              onAbort={() => resetAndClose()}
              modalButtons={[
                { label:"Cancel", onClick: () => resetAndClose() },
                { label:"Accept", onClick: () => accept() }, 
              ]}
              enableModal={props.show} 
              modalInnerComponent={<>
                                    <div>
                                        <div><strong>Please a insert a document name to create a reservoir study document</strong></div>
                                        <br/>
                                        <div className="assurance-selector-container">
                                              <FluentUIDecorator label={"Name"} info={null}
                                                              fluentComponent={FluentUIDecoratorTypes.TextField(textFieldProps)}
                                                            />
                                             
                                              <FluentUIDecorator label={"Country"} info={null}
                                                              fluentComponent={FluentUIDecoratorTypes.ComboBox(countryDropdownProps)}
                                                            />
                                          </div>
                                          {showWarning && <div>
                                            <br />
                                            <MessageBar messageBarType={MessageBarType.warning} dismissButtonAriaLabel="Close">
                                                Please insert a name and choose a country to continue.
                                            </MessageBar>
                                          </div>}
                                      </div>
                                    </>} /></div>);
}
