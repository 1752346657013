import { Route, Routes, useNavigate } from "react-router-dom";
import AppRoutesMap from "./AppRoutesMap";
import { Bootstrapper } from "./Components/Bootstrapper/Bootstrapper";
import { AppToolbar } from "./Components/AppToolbar/AppToolbar";
import SampleUserMenu from "./Components/SampleUserMenu/SampleUserMenu";

export const AppLayout = () => {
  return (
    <Bootstrapper>
      <AppToolbar type="navigation-voices" /> 
      <SampleUserMenu />
      <Routes>
        {AppRoutesMap.map((x, i) => {
          return <Route key={i} path={x.path} element={x.element} />;
        })}
      </Routes>
    </Bootstrapper>
  );
};
