import { SET_USER, UserActionType } from "./UserAction";
import { UserState } from "./UserState";

const getLoggedUser = () => {
  let u: any = localStorage.getItem("logged-user");
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const DEFAULT_STATE: UserState = {
  currentUser: getLoggedUser(),
};

export const userReducer = (
  state: UserState = DEFAULT_STATE,
  action: UserActionType
): UserState => {
  switch (action.type) {
    case SET_USER:
      if (action.payload !== undefined) {
        localStorage.setItem("logged-user", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("logged-user");
      }
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};
