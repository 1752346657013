import axios from "axios";
import { IBO } from "../../Models/IAssurance";
import { IBoSuggestion } from "../../Models/IBoSuggestion";
import { IDocumentChapter, IReportData } from "../../Models/IDocumentChapter";
import { ITextSuggestion } from "../../Models/ITextSuggestion";
import {REPORT_CORE_API_ENDPOINT} from "../../Constants/ApiUrls";
import { concatUrl } from "../../Utils/Utils";

export const getBoTitleAndSection = (chapter: IDocumentChapter, report: IReportData,)=>{
  var title = " "
  var section = "1"
  if(chapter.level>2){
    const fauxPrefix = chapter.prefix.slice(0,3)
    var chapterList = [];
    report.chapters.forEach((reporChapter =>{
      chapterList = chapterList.concat(createChapterList([], reporChapter))
    }))
    const fauxTitle = chapterList.find((chapter)=>chapter.prefix==fauxPrefix)?.title
    title = fauxPrefix + ". " +fauxTitle
  }else{
    title = chapter.prefix ? `${chapter.prefix}. ${chapter.title}` : chapter.title; 
    section = chapter.prefix ? chapter.prefix.split(".")[0] : "0"
  }

  return {
    "title": title,
    "section": section
  }
}

const createChapterList = (chapterList: IDocumentChapter[], chapter: IDocumentChapter) => {
  chapterList.push(chapter);
  if(chapter.subChapters && chapter.subChapters.length){
    let newChapterList = [];
    chapter.subChapters.forEach(sub_chapter=>{
      chapterList = chapterList.concat(createChapterList(newChapterList, sub_chapter))});
  }
  return chapterList;
 
  }
const ReportController = {
  /** esempio di chiamata GET */
  getBoListByChapter: async (chapter: IDocumentChapter, report: IReportData, callback: (boList: IBoSuggestion[]) => void) => {
    if (chapter) {
      var titleAndSection = getBoTitleAndSection(chapter, report);
      const title = titleAndSection['title']
      const section = titleAndSection['section']
     
      axios.post(concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_BOLIST),
        {
          "country": report.info.countryName,
          "title": title,
          "section": section
        }, { withCredentials: true }).then((response) => {
          if (response.data.kb_response) {
            if (callback) {
              callback(response.data.kb_response.bOs);
            }
            return response.data.kb_response.bOs
          }else if(response.data.prd_kb_response) {
            if (callback) {
              callback(response.data.prd_kb_response.bOs);
            }
            return response.data.prd_kb_response.bOs
        }}).catch((error) => {
            console.log(error)
        })

    }
  },
  getReport: async (reportId: string, callback?: (response: IReportData) => void) => {
    console.log("reportid", reportId)
    const fixChapterStructure = (chapter: any) => {
      return {
        id: chapter.id,
        reportId: chapter.report_id,
        prefix: chapter.prefix,
        title: chapter.title,
        content: chapter.content,
        users: chapter.users,
        level: chapter.level,
        contentId: chapter.content_id,
        subChapters: chapter.sub_chapters?.map(subChapter => fixChapterStructure(subChapter))
      }
    }

    axios.get(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_REPORTBYID),
      { withCredentials: true, params: {report_id: reportId} })
      .then(function (response) {
        const reportData = response.data
        const chapters = reportData.chapters.map(chapter => fixChapterStructure(chapter))
        const report: IReportData = {
          info: {
            _id: reportData._id,
            reportType: reportData.type,
            countryName: reportData.country.name,
            assuranceCode: reportData.assurance_code,
            owner: reportData.created_by.fullname,
            lastModified: reportData.update_date,
            status: reportData.activation_status,
            creationDate: reportData.creation_date,
            lastModifiedBy:reportData.updated_by.fullname,
            assuranceType: reportData.assurance_type
          },
          chapters: chapters,
        };

        if (callback) {
          callback(report);
        }
        return report;
      })
      .catch(function (error) {
        console.log(error);
      });

  },
  saveChapter: async (chapter: IDocumentChapter, callback: () => void) => {
    const fixChapterStructure = (chapter: IDocumentChapter) => {
      return {
        id: chapter.id,
        report_id: chapter.reportId,
        prefix: chapter.prefix,
        title: chapter.title,
        content: chapter.content,
        content_id: chapter.contentId,
        users: chapter.users,
        level: chapter.level,
        sub_chapters: chapter.subChapters?.map(subChapter => fixChapterStructure(subChapter))

      } as any
    }
    const formattedChapter = fixChapterStructure(chapter)

    axios.post(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.POST_SAVECHAPTER),
      {
        "chapter": formattedChapter
      }, { withCredentials: true })
      .then(function (response) {
        console.log(response);
        callback();
      })
      .catch(function (error) {
        console.log(error);
      });

  },
  getBoSuggestions: (chapterUnderEdit: IDocumentChapter, report: IReportData, bo: IBO, callback: (suggestions: ITextSuggestion[]) => void) => {
    if (chapterUnderEdit) {
      var titleAndSection = getBoTitleAndSection(chapterUnderEdit, report);
      const title = titleAndSection['title']
      const section = titleAndSection['section']
      axios.post(
        concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_BOSUGGESTIONS), {
          "business_object": bo.name,
          "title": title,
          "country": report.info.countryName,
          "section": section,
          "nr_suggestions": ""
        }, { withCredentials: true }).then((response) => {
          const data = response.data;
          if (data.kb_response) {
            const data_response = data.kb_response.map((bos) => {
              return {
                _id: bos._id,
                boName: bo.name,
                content: bos.content,
                user: bos.authors,
                date: bos.document_date,
                score: bos.overall_score,
                stars: bos.overall_score/20
              } as ITextSuggestion
            })
            if (callback) {
              callback(data_response);
            }

            return data_response;
          }else if (data.prd_kb_response) {
            const data_response = data.prd_kb_response.map((bos) => {
              return {
                boName: bo.name,
                content: bos.content,
                user: bos.authors,
                date: bos.document_date,
                score: bos.overall_score,
                stars: bos.overall_score/20
              }
            })
            if (callback) {
              callback(data_response);
            }

            return data_response;
          }
        }).catch((error) => {
          console.log(error)
        })

    }
  },

  sendClickedBoIds: (idList:string[], callback:()=>void) =>{
    axios.post(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.POST_BOCLICKED),
      {
        "clicked_sentences_ids": idList
      }, { withCredentials: true })
      .then(function (response) {
        console.log(response);
        callback();
      })
      .catch(function (error) {
        console.log(error);
      });

  }

};

export default ReportController;
