import { IBO } from "../../Models/IAssurance";

export enum NavbarState {
  Home,
  SelectedFiles,
  Report
}

export interface GenericState {
  countries: IBO[];
  showAssuranceSelector: boolean;
  showReservoirCreation: boolean;
  navbarState: NavbarState;
  selectedFiles: string[];
}
