import { act } from "react-dom/test-utils";
import { IDocumentChapter } from "../../Models/IDocumentChapter";
import { deepCopy } from "../../Services/Internal/DeepCopy";
import {
  GenericActionType, SET_BO_HIGHLIGHT, SET_BO_LIST, SET_CHAPTER_UNDER_EDIT, SET_DOCUMENT_CHAPTERS, SET_EDITOR_CONTENT, SET_EDITOR_SCROLL, SET_INSERTED_BOLIST, SET_SAVED_CHAPTER, SET_SHOW_PEOPLE_PICKER_MODAL, SET_SUGGESTION_SEARCH, SET_TEXT_SUGGESTION, 
} from "./ReportEditorAction";
import { ReportEditorState } from "./ReportEditorState";

const REPORT_PAGE_DEFAULT_STATE: ReportEditorState = {
  reportData: null,
  chapterUnderEdit: null,
  suggestionSearch: "",
  chapterToScroll: null,
  editrContent: "",
  boSuggestions: [],
  peoplePickerModal: {
    show: false,
    chapter: null
  },
  businessObjectsList: [],
};

const assignNewChapter = (newChapterToUpdate: IDocumentChapter, oldChapters: IDocumentChapter[]) : void => {
  oldChapters.forEach(oldChapter => {
    if(newChapterToUpdate.id == oldChapter.id)
    {
      oldChapter.users = newChapterToUpdate.users;
      oldChapter.content = newChapterToUpdate.content;
    }
    assignNewChapter(newChapterToUpdate, oldChapter.subChapters || []);
  })
}

export const reportEditorReducer = (
  state: ReportEditorState = REPORT_PAGE_DEFAULT_STATE,
  action: GenericActionType
): ReportEditorState => {
  switch (action.type) {
    case SET_BO_HIGHLIGHT:
      const boRegex = /#((\w)*)/gm;
      const boHighlight = `<span style="color: rgb(255, 255, 255); background-color: #00704a;">${action.payload.name}</span>&nbsp;`;
      const contentWithBoHighligh = state.editrContent.replace(boRegex, boHighlight);
      return {
        ...state,
        editrContent: contentWithBoHighligh
      };
    case SET_TEXT_SUGGESTION:
      const matchingFragment = `${action.payload.boName}</span>`;
      const replacingFragment = `${action.payload.boName}</span> ${action.payload.content}`;
      const textRegex = new RegExp(matchingFragment);
      const resultWithTextSuggestion = state.editrContent.replace(textRegex, replacingFragment);
      const _id = action.payload._id
      return {
        ...state,
        editrContent: resultWithTextSuggestion,
        businessObjectsList: [...state.businessObjectsList, _id]
      }
    case SET_SUGGESTION_SEARCH:
      return {
        ...state,
        suggestionSearch: action.payload,
      }
    case SET_EDITOR_SCROLL:
      return {
        ...state,
        chapterToScroll: action.payload
      }
    case SET_EDITOR_CONTENT:
      return {
        ...state,
        editrContent: action.payload,
      }
    case SET_CHAPTER_UNDER_EDIT:
      return {
        ...state,
        chapterUnderEdit: action.payload,
      };
    case SET_DOCUMENT_CHAPTERS:
      return {
        ...state,
        reportData: action.payload,
      };
    case SET_SAVED_CHAPTER:
      let newChapters = deepCopy(state.reportData.chapters);
      assignNewChapter(action.payload, newChapters);

      return {
        ...state,
        reportData: {
          ...state.reportData,
          chapters: newChapters
        },
      };
    case SET_BO_LIST:
      return {
        ...state,
        boSuggestions: action.payload
      }
    case SET_SHOW_PEOPLE_PICKER_MODAL: {
      return {
        ...state,
        peoplePickerModal: action.payload
      }
    }
    case SET_INSERTED_BOLIST: {
      return {
        ...state,
        businessObjectsList: action.payload
      }
    }
    default:
      return state;
  }
};
