import { IUser } from "../../Models/IUser";

export const SET_USER = "SET_USER";

interface SetUserActionType {
  type: typeof SET_USER;
  payload: IUser | undefined;
}

export type UserActionType = SetUserActionType;

const setUser = (user: IUser | undefined): SetUserActionType => {
  return { type: SET_USER, payload: user };
};

export const UserActions = {
  setUser,
};
