import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { REPORT_CORE_API_ENDPOINT } from "../../Constants/ApiUrls";
import { IReportData } from "../../Models/IDocumentChapter";
import { concatUrl } from "../../Utils/Utils";
import "./ReservoirEditorBar.scss";

export const ReservoirEditorBar = (props: { reportData:IReportData }) => {
  const {reportData} = props;
  console.log(reportData)

    return (
        <div className="flex-space-between report-bar">
          <div className="title">{reportData?.info?.assuranceCode}</div>
          {/* <div className="title">{"TAR - EAR project review document"}</div>*/}
          <div>
          <DefaultButton
            text="Export"
            iconProps={{ iconName: 'Download' }}
            disabled={true}
            href={concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.EXPORT_DOCX + "?report_id=" + reportData?.info._id)}
          />
          </div>
        </div>
      );
}