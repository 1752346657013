import { IBO } from "../../Models/IAssurance";
import { IBoSuggestion } from "../../Models/IBoSuggestion";
import { IDocumentChapter, IReportData } from "../../Models/IDocumentChapter";
import { ITextSuggestion } from "../../Models/ITextSuggestion";
import { PeoplePickerModal } from "./ReportEditorState";

export const SET_CHAPTER_UNDER_EDIT = "SET_CHAPTER_UNDER_EDIT";
export const SET_DOCUMENT_CHAPTERS = "SET_DOCUMENT_CHAPTERS";
export const SET_SUGGESTION_SEARCH = "SET_SUGGESTION_SEARCH";
export const SET_SAVED_CHAPTER = "SET_SAVED_CHAPTER";
export const SET_BO_LIST = "SET_BO_LIST";
export const SET_EDITOR_CONTENT = "SET_EDITOR_CONTENT";
export const SET_BO_HIGHLIGHT = "SET_BO_HIGHLIGHT";
export const SET_TEXT_SUGGESTION = "SET_TEXT_SUGGESTION";
export const SET_EDITOR_SCROLL = "SET_EDITOR_SCROLL";
export const SET_SHOW_PEOPLE_PICKER_MODAL = "SET_SHOW_PEOPLE_PICKER_MODAL";
export const SET_INSERTED_BOLIST = "SET_INSERTED_BOLIST";

// TODO MR CR-12-2022

interface SetEditorScrollAction {
  type: typeof SET_EDITOR_SCROLL;
  payload: string;
}

interface SetBoHighlightAction {
  type: typeof SET_BO_HIGHLIGHT;
  payload: IBoSuggestion;
}

interface SetTextSuggestionAction {
  type: typeof SET_TEXT_SUGGESTION;
  payload: ITextSuggestion;
}
interface SetChapterUnderEditAction {
  type: typeof SET_CHAPTER_UNDER_EDIT;
  payload: IDocumentChapter;
}
interface SetEditorContentAction {
  type: typeof SET_EDITOR_CONTENT;
  payload: string;
}

interface SetSuggestionSearchAction {
  type: typeof SET_SUGGESTION_SEARCH;
  payload: string;
}

interface SetReportDataAction {
  type: typeof SET_DOCUMENT_CHAPTERS;
  payload: IReportData;
}

interface SetSavedChapterAction {
  type: typeof SET_SAVED_CHAPTER;
  payload: IDocumentChapter;
}

interface SetBoListAction {
  type: typeof SET_BO_LIST;
  payload: IBoSuggestion[];
}

interface SetShowPeoplePickerModal {
  type: typeof SET_SHOW_PEOPLE_PICKER_MODAL;
  payload: PeoplePickerModal;
}

interface SetInsertedBOListAction{
  type: typeof SET_INSERTED_BOLIST;
  payload: string[]
}
export type GenericActionType = SetChapterUnderEditAction | SetReportDataAction |
                                SetBoHighlightAction | SetTextSuggestionAction |
                                SetSavedChapterAction | SetBoListAction | 
                                SetSuggestionSearchAction | SetEditorContentAction |
                                SetEditorScrollAction | SetShowPeoplePickerModal | 
                                SetInsertedBOListAction;

const setChapterIdUnderEditAction = (chapter: IDocumentChapter): SetChapterUnderEditAction => {
  return { type: SET_CHAPTER_UNDER_EDIT, payload: chapter };
};
const setEditorScrollAction = (chapterId: string): SetEditorScrollAction => {
  return { type: SET_EDITOR_SCROLL, payload: chapterId };
};

const setBoHighlightAction = (data: IBoSuggestion): SetBoHighlightAction => {
  return { type: SET_BO_HIGHLIGHT, payload: data };
};

const setTextSuggestionAction = (data: ITextSuggestion): SetTextSuggestionAction => {
  return { type: SET_TEXT_SUGGESTION, payload: data };
};

const setReportDataActionAction = (data: IReportData): SetReportDataAction => {
  return { type: SET_DOCUMENT_CHAPTERS, payload: data };
};

const setSavedChapterAction = (data: IDocumentChapter): SetSavedChapterAction => {
  return { type: SET_SAVED_CHAPTER, payload: data };
};

const setSuggestionSearchAction = (data: string): SetSuggestionSearchAction => {
  return { type: SET_SUGGESTION_SEARCH, payload: data };
};

const setEditorContentAction = (data: string): SetEditorContentAction => {
  return { type: SET_EDITOR_CONTENT, payload: data };
};

const setBoListAction = (data: IBoSuggestion[]): SetBoListAction => {
  return { type: SET_BO_LIST, payload: data };
};

const setShowPeoplePickerModal = (data: PeoplePickerModal): SetShowPeoplePickerModal => {
  return { type: SET_SHOW_PEOPLE_PICKER_MODAL, payload: data };
};

const setBusinessObjectListAction = (data: string[]): SetInsertedBOListAction => {
  return { type: SET_INSERTED_BOLIST, payload: data };
}

export const ReportEditorAction = {
  setChapterUnderEditAction: setChapterIdUnderEditAction,
  setReportDataActionAction,
  setSavedChapterAction,
  setSuggestionSearchAction,
  setBoListAction,
  setEditorContentAction,
  setEditorScrollAction,
  setBoHighlightAction,
  setTextSuggestionAction,
  setShowPeoplePickerModal,
  setBusinessObjectListAction
};
