const BASE_ROUTE = "report-assistant/";
const HOME_PAGE = `${BASE_ROUTE}home`;
const REPORT_PAGE = `${BASE_ROUTE}report`;
const DASHBOARD_PAGE = `${BASE_ROUTE}dashboard`;
const RESERVOIR_PAGE = `${BASE_ROUTE}reservoir`;

const AppRoutes = {
  BASE_ROUTE,
  HOME_PAGE,
  REPORT_PAGE,
  RESERVOIR_PAGE,
  DASHBOARD_PAGE,
};

export default AppRoutes;
