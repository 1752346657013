import {
  _randomDate,
  _randomFileExtension,
  _randomFileSize,
  _randomName,
} from "./Random";

export interface IFileListMockDataItem {
  name: string;
  updateDate: string;
  size: string;
  status: string;
  extra: { version: number };
}

const statuses = ["Complete", "Draft", "Deleted"];

export const FileListMockData: IFileListMockDataItem[] = [];

for (let i = 0; i < 15; i++) {
  FileListMockData.push({
    name: _randomName(2) + _randomFileExtension(),
    size: _randomFileSize().value,
    updateDate: _randomDate(new Date(2012, 0, 1), new Date()).dateFormatted,
    status: statuses[Math.floor(Math.random() * statuses.length)],
    extra: {
      version: Math.floor(Math.random() * 100) + 30,
    },
  });
}
