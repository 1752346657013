import "./HomePage.scss";
import { AuthenticationHelper, FileList, SharedState } from "@Eni/docware-fe-master";
import { useEffect, useState } from "react";
import { IReportItem } from "../../Models/IReportItem";
import ApiService from "../../Services/ApiService";
import { StatusLabel } from "../../Components/StatusButton/StatusButton";
import { DocumentButton } from "../../Components/DocumentButton/DocumentButton";
import { AssuranceSelector } from "../../Components/AssuranceSelector/AssuranceSelector";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../Reducers/RootReducer";
import { GenericActions } from "../../Reducers/Generic/GenericAction";
import { Link } from "react-router-dom";
import AppRoutes from "../../Constants/AppRoutes";
import { NavbarState } from "../../Reducers/Generic/GenericState";
import { ReservoirCreator } from "../../Components/ReservoirCreator/ReservoirCreator";
import UserController from "../../Services/Controllers/UserController";

const HomePage = () => {
  const dispatch = useDispatch();
  const showAssuranceSelector = useSelector<GlobalState, boolean>(state => state.generic.showAssuranceSelector);
  const showReservoirCreation = useSelector<GlobalState, boolean>(state => state.generic.showReservoirCreation);
  const setShowAssuranceModal = (value: boolean) => {
    dispatch(GenericActions.setShowAssuranceSelectorAction(value));
  };
  const setShowReservoirModal = (value: boolean) =>{
    dispatch(GenericActions.setShowReservoirCreatorAction(value));
  }

  const selectedFiles: string[] = useSelector<GlobalState, string[]>(state => state.generic.selectedFiles);
  const [selectedItems, setSelectedItems] = useState<IReportItem[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [welcomeString, setWelcomeString] = useState<string>("");
  const [reportList, setReportList] = useState<IReportItem[]>([]);

  useEffect(() => {
    dispatch(GenericActions.setNavbarStateAction(NavbarState.Home));
  }, []);

  const postLoginProcess = () => {
    console.log("HI I succeded")
    UserController.getUser((user:any) => {
      if(Object.keys(user).length){
        localStorage.setItem('logged-user', JSON.stringify(user));
        setUserName(user.firstName);
        setWelcomeString("Welcome, ")
        ApiService.AssuranceController.getUserCountries(countries => {
          dispatch(GenericActions.setCountriesAction(countries));
        });
        ApiService.ReportListController.getReportList((reportList) => {
          setReportList(reportList);
        });
      }
    })  
  }

  const failedLoginProcess = () => {
    console.log("HI I failed")
  }

  const startLoginProcedure = () => {
    console.log("HI")
    AuthenticationHelper.startLoginRoutine().catch((e) => {
      console.error(e);
      failedLoginProcess();
    });
  }

  useEffect(() => {
    SharedState.authStatus.subscribe((status) => {
      console.debug('[auth] SharedState.authStatus subscribe. status: ', status);
      if (status.error) {
        failedLoginProcess();
        return;
      }
      if (status.accessToken) {
        postLoginProcess();
      } else {
        if (!status.pending) {
          startLoginProcedure();
        }
      }
    });
  }, []);

  useEffect(() =>{
    let items = reportList?.filter((item) =>selectedFiles.includes(item._id ))
    setSelectedItems(items);
  }, [selectedFiles]);

  const openAssuranceModal = () => {
    setShowAssuranceModal(true);
  };

  const openReservoirModal = () => {
    setShowReservoirModal(true);
  }

  const selectItems = (items: IReportItem[]) => {
    dispatch(GenericActions.setSelectedFilesAction({
      navbarState: items.length > 0 ? NavbarState.SelectedFiles : NavbarState.Home,
      selectedFiles: items.map(i => i._id)
    }));
    setSelectedItems(items)
  }

  return (
    <div className="generic-page-padwrap">
      <div className="page-title">{welcomeString}<span className="user-name-title">{userName}</span></div>

      <div className="page-sub-title">CREATE</div>

      <div className="document-button-container">
        <DocumentButton title="PRD" subtitle="Project Review Document" onClick={openAssuranceModal}/>
        <DocumentButton title="IRS" subtitle="Integrated Reservoir Study" onClick={openReservoirModal} />
        <DocumentButton title="GR" subtitle="Geophysical Report"/>
      </div>

      <div className="page-sub-title">MY REPORTS</div>
      <div>
          <FileList
                defaultSortOnThisColumnIndex={2}
                columns={[
                  {
                    name: "Assurance",
                    dataType: null,
                    fieldName: null,
                    iconName: null,
                    size: "medium",
                    onRender: (item: IReportItem) => {
                      if (item.reportType =="PRD"){
                        return <Link className="report-link" to={`/${AppRoutes.REPORT_PAGE}/${item._id}`}>{item.assuranceCode}</Link>
                    
                      }else{
                        return <Link className="report-link" to={`/${AppRoutes.RESERVOIR_PAGE}/${item._id}`}>{item.assuranceCode}</Link>
                  
                      }
                       }
                  },
                  {
                    name: "Type",
                    dataType: "string",
                    fieldName: "reportType",
                    iconName: null,
                    size: "small",
                  },
                  {
                    name: "Country",
                    dataType: "string",
                    fieldName: "countryName",
                    iconName: null,
                    size: "medium",
                  },
                  {
                    name: "Owner",
                    dataType: "string",
                    fieldName: "owner",
                    iconName: null,
                    size: "small",
                  },
                  {
                    name: "Last Edited",
                    dataType: "string",
                    fieldName: "lastModified",
                    iconName: null,
                    size: "small",
                  },
                  {
                    name: "Status",
                    dataType: null,
                    fieldName: null,
                    iconName: null,
                    size: "medium",
                    onRender: (item: IReportItem) => {
                      return <StatusLabel status={item.status} />
                    },
                  },
                ]}
                fileIconFromField={"name"}
                items={reportList}
                onItemsSelected={selectItems}
                currentSelectedItems={selectedItems}
                currentSelectedItem={null}
                hideBottomButton={true}
              />
      </div>

      <div>
        <AssuranceSelector show={showAssuranceSelector} setShow={setShowAssuranceModal}/>
      </div>
      <div>
        <ReservoirCreator show={showReservoirCreation} setShow={setShowReservoirModal}/>
      </div>
    </div>
  );
};

export default HomePage;
// enableModal: boolean;
// modalButtons?: ModalDialogButtonInfo[];
// modalTitle?: string;
// modalMessage?: string;
// modalInnerComponent?: any;
// onAccept?: () => void;
// onAbort?: () => void;
// loadingButtons?: boolean;