import { IAssurance, IAssuranceSearchOptions, IBO } from "../../Models/IAssurance";
import { REPORT_CORE_API_ENDPOINT } from "../../Constants/ApiUrls";
import axios from "axios";
import { ICountry } from "../../Models/ICountry";
import { concatUrl } from "../../Utils/Utils"

const AssuranceController = {
  getUserCountries: async (callback?: (response: IBO[]) => void) => {
    axios.get(concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_USERCOUNTRIES),
      { withCredentials: true }).then((response) => {
        console.log(response);
        const countries: ICountry[] = response.data
        if (callback) {
          callback(countries);
        }
        return countries;
      })
      .catch((error) => {
        console.log(error);
      });

  },
  getAssuranceSearchOptions: async (countryCode: string, callback?: (response: IAssuranceSearchOptions) => void) => {
    axios.get(concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_ASSURANCEBYCOUNTRY),
      { withCredentials: true, params: { country_code: countryCode } }).then((response) => {
        console.log(response);
        if (callback) {
          callback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

  },
  getAssurance: async (assuranceCode: string, callback?: (response: IAssurance) => void) => {
    axios.get(concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_ASSURANCEBYCODE),
      { withCredentials: true, params: { assurance_code: assuranceCode } }).then((response) => {
        console.log(response);
        if (callback) {
          callback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

  },
  createPrd: async (assuranceCode: string, callback?: (reportId: string) => void) => {
    axios.post(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.POST_CREATEREPORT),
      {
        "assurance_code": assuranceCode
      }, { withCredentials: true }).then((response) => {
        console.log(response);
        if (callback) {
          callback(response.data);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default AssuranceController;
