import { Icon } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import "./AccordionContent.scss";

export interface IAccordionContentProps {
  title: string;
  content: JSX.Element;
}

const AccordionContent = (props: IAccordionContentProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="accordion-wrap">
      <div
        className="accordion-title"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span style={{ marginRight: "1em", marginLeft: "0.5em" }}>
          <Icon
            className={!isOpen ? "" : "accordion-tilted-chevron"}
            iconName="ChevronRight"
          />
        </span>
        <span>{props.title}</span>
      </div>
      <div
        className={"accordion-content"}
        style={isOpen ? {} : { maxHeight: "0px" }}
      >
        {props.content}
      </div>
    </div>
  );
};

export default AccordionContent;
