export const REPORT_CORE_API_ENDPOINT = {
  GET_USERCOUNTRIES: "/prd/user-countries",
  GET_ASSURANCEBYCOUNTRY: "/prd/assurances",
  GET_ASSURANCEBYCODE: "/prd/assurance",
  POST_CREATEREPORT: "/prd/create-report",
  GET_BOLIST: "/prd/bo",
  GET_REPORTBYID: "/prd/report",
  POST_SAVECHAPTER: "/prd/save-chapter",
  GET_BOSUGGESTIONS: "/prd/get_suggestions",
  GET_REPORTS: "/prd/reports",
  DELETE_REPORT: "/prd/delete-report",
  GET_USER_SUGGESTIONS: "/prd/users",
  EXPORT_DOCX: "/prd/export-doc",
  POST_BOCLICKED: "/prd/clicked-bo",
  POST_CREATE_RESERVOIR_STUDY: "/prd/reservoir",
  GET_USER:"/prd/who-am-i"
};
