import { FileList } from "@Eni/docware-fe-master";
import { Link } from "@fluentui/react";
import { useState } from "react";
import AccordionContent from "../../Components/AccordionContent/AccordionContent";
import {
  FileListMockData,
  IFileListMockDataItem,
} from "../../MockData/FileListMockData";
import "./DesignSystem.scss";

const DesignSystem = () => {
  return (
    <div>
      <div className="generic-page-title">Home Page</div>
      <h2>Introduzione</h2>
      <div className="home-page-explain-box">
        {"Ben venuti sull'App template con "}
        <Link href={"https://martinfowler.com/articles/micro-frontends.html"}>
          <strong>Micro Frontend </strong>
        </Link>
        {"e "}
        <strong>
          <Link href={"https://developer.microsoft.com/en-us/fluentui"}>
            Fluent UI
          </Link>
        </strong>{" "}
        per l'abiente ENI di <strong>Docware</strong>.
        <br />
        <br />
        Lo scopo di questa App è di fornire uno scheletro di progetto per
        applicativi futuri da integrare nel mondo Docware. In questa App
        verranno integrati e resi disponibili esempi di utilizzo dei componenti
        della libreria Fluent che sono stati customizzati e resi accessibili in
        tutte le App del mondo Docware. Questa applicazione presuppone di essere
        eseguita all'interno del mondo Docware e non può funzionare in altri
        ambienti out of the box.
        <br />
        <br />
        Questa App Template include:
        <ul>
          <li>
            Un template configurato e strutturato{" "}
            <strong>
              pronto per l'esecuzione in ambiente Docware, out of the box.
            </strong>
          </li>
          <li>
            Una serie di esempi documentati sui{" "}
            <strong>componenti Shared</strong>, ossia quei componenti custom di
            Fluent UI condivisi da tutti gli applicativi del mondo Docware.
          </li>
          <li>
            Un sistema di <strong>Routing già pronto</strong>. Per estenderlo è
            sufficiente aggiungere voci nel file AppRoutesMap.tsx.
          </li>
          <li>
            Un gestore dello stato con <strong>Redux</strong> pronto per essere
            usato ed esteso.
          </li>
          <li>
            Un servizio di controller API potente e facilmente estendibile
            tramite il pattern dei <strong>controller</strong>.
          </li>
        </ul>
      </div>

      <div>
        <h2>Componenti Shared</h2>
        <div className="home-page-explain-box">
          Di seguito abbiamo la lista (non necessariamente aggiornata) dei
          componenti Shared disponibili per i micro frontend del mondo Docware.
          Gli esempi sono costruiti per permettere la massima facilità di
          riutilizzo del componente in casi d'uso reali. Tutti gli esempi
          presuppongono una conoscenza di base della UI di Fluent.
        </div>
        <br />
        <AccordionContent
          title="FileList"
          content={
            <div className="home-page-explain-box">
              <h4>Introduzione</h4>
              Il componente FileList estende la lista di Fluent UI ed è
              ottimizzato per liste di file. Attualmente supporta le seguenti
              feature:
              <ul>
                <li>
                  Colonna iniziale con popolamento automatico dell'icona del
                  tipo di file.
                </li>
                <li>Ordinamento su tutte le colonne.</li>
                <li>
                  Definizione delle colonne migliorata (maggiori informazioni
                  più avanti).*
                </li>
                <li>
                  Callback che segnalino le righe selezionate (sia multiple che
                  singole).*
                </li>
                <li>
                  Possibilità di preselezionare le righe programmaticamente.*
                </li>
                <li>
                  Gestione della selezione esclusiva tra tabelle nella stessa
                  pagina:{" "}
                  <strong>
                    selezionare una riga nella tabella 1 deselezionerà tutte le
                    righe nella tabella 2 della stessa pagina, utile quando la
                    selezione governa anche la barra dei comandi.
                  </strong>
                  *
                </li>
              </ul>
              Le feature segnate con asterisco non sono supportate nativamente
              dalle liste di Fluent UI e non appaiono nei tutorial ufficiali
              Microsoft.
              <br />
              Non tutte le feature della tabella sono discusse in questa guida.
              L'ispezione del codice rimane la fonte di dettaglio sul
              funzionamento del componente.
              <br />
              <br />
              <h4>Definizione migliorata delle colonne</h4>
              La definizione migliorata delle colonne permette di mascherare
              l'articolata sintassi delle tabelle di Fluent UI e richiede solo
              la definizione di pochi campi fondamentali.
              <ul>
                <li>
                  <strong>name</strong>: Il display name nell'header della
                  colonna
                </li>
                <li>
                  <strong>dataType</strong>: tipo di dato, es string, number,
                  date, etc...
                </li>
                <li>
                  <strong>fieldName</strong>: chiave del valore da mostrare in
                  tabella
                </li>
                <li>
                  <strong>iconName</strong>: se diverso da null, la colonna sarà
                  per le icone dei file. iconName deve essere il nome dell'icona
                  di Fluent UI che si vuole visualizzare dentro l'header. In una
                  implementazione futura il campo potrà essere omesso.
                </li>
                <li>
                  <strong>size</strong>: spaziatura della colonna, es tiny,
                  small, medium, large, etc...
                </li>
                <li>
                  <strong>onRender</strong>: (opzionale) funzione di
                  renderizzazione custom per la colonna.
                </li>
              </ul>
              Una feature molto utile è la possibilità di estrarre i valori da
              mostrare in tabella tramite path JSON, ad es nella tabella
              seguente la colonna Version è popolata usando{" "}
              <strong>fieldName = extra.version</strong> che è il percorso del
              dato nella struttura JSON, es per la prima riga:
              <div className="home-code-snippe">
                {JSON.stringify(FileListMockData[0], null, 4)}
              </div>
              <h4>Gestione selezioni</h4>
              Le selezioni sono controllate da quattro attributi: due
              inizializzano la selezione, uno gestisce i mutamenti delle
              selezioni e uno genera l'isolamento delle selezioni tra tabelle.
              Andiamo a vederli in ordine:
              <br />
              <br />
              <ul>
                <li>
                  Gli attributi <strong>currentSelectedItem</strong> e{" "}
                  <strong>currentSelectedItems</strong> sono usati per
                  selezionare programmaticamente le righe della tabella, il
                  primo nel caso di selezione singola, il secondo in caso di
                  selezione multipla.
                </li>
                <li>
                  L'attributo <strong>onItemsSelected</strong> viene invocato
                  ogni volta che la lista di righe selezionate cambia, e vale
                  sia per selezione singola che multipla.
                </li>
                <li>
                  L'attributo <strong>tableName</strong> da un nome interno alla
                  tabella. Le tabelle con nome interno avranno selezioni
                  mutuamente esclusive quando più tabelle con nome interno
                  risultano presenti nella stessa pagina.
                </li>
              </ul>
              <h4>Esempio di uso della tabella</h4>
              <FileList
                defaultSortOnThisColumnIndex={2}
                columns={[
                  {
                    name: "File Type",
                    dataType: "string",
                    fieldName: "name",
                    iconName: "Page",
                    size: "tiny",
                  },
                  {
                    name: "Version",
                    dataType: "number",
                    fieldName: "extra.version",
                    iconName: null,
                    size: "small",
                  },
                  {
                    name: "Document Name",
                    dataType: "string",
                    fieldName: "name",
                    iconName: null,
                    size: "medium",
                  },
                  {
                    name: "Last modified",
                    dataType: "string",
                    fieldName: "updateDate",
                    iconName: null,
                    size: "medium",
                  },
                  {
                    name: "Size",
                    dataType: "number",
                    fieldName: "size",
                    iconName: null,
                    size: "small",
                  },
                  {
                    name: "Custom column",
                    dataType: null,
                    fieldName: null,
                    iconName: null,
                    size: "medium",
                    onRender: (item: IFileListMockDataItem) => {
                      return (
                        <div
                          className="custom-file-list-item-class"
                          style={{
                            background:
                              item.status === "Draft"
                                ? "white"
                                : item.status === "Complete"
                                ? "#b1e2b1"
                                : "#f67676",
                          }}
                        >
                          {item.status}
                        </div>
                      );
                    },
                  },
                ]}
                fileIconFromField={"name"}
                items={FileListMockData}
                onItemsSelected={(item: any) => {}}
                currentSelectedItems={[]}
                currentSelectedItem={null}
                hideBottomButton={true}
              />
            </div>
          }
        />
        <AccordionContent
          title="SampleDocumentManagementToolbar"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
        <AccordionContent
          title="FluentUIDecorator"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
        <AccordionContent
          title="ModalDialog"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
        <AccordionContent
          title="SampleUserMenu"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
        <AccordionContent
          title="LabelButton"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
        <AccordionContent
          title="PivotFluentWrap"
          content={<div className="home-page-explain-box">In costruzione</div>}
        />
      </div>
    </div>
  );
};

export default DesignSystem;
