import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { ProviderWrapper } from "./ProviderWrapper";
import { AppLayout } from "./AppLayout";
import { ThemeProvider, PartialTheme } from "@fluentui/react";
import packageJson from "../package.json";
import "./App.scss";
import { useEffect } from "react";

// SET THE APP THEME COLORS HERE AND ONLY HERE
const appTheme: PartialTheme = {
  palette: {
    themePrimary: '#1e8966',
    themeLighterAlt: '#f2faf8',
    themeLighter: '#cfece2',
    themeLight: '#a8dccb',
    themeTertiary: '#62b99c',
    themeSecondary: '#309875',
    themeDarkAlt: '#1b7c5c',
    themeDark: '#17694d',
    themeDarker: '#114d39',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
};

export const App = () => {
  useEffect(() => {
    console.log("PACKAGE JSON VERSION", packageJson.version);
  }, []);
  return (
    <ThemeProvider theme={appTheme}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ProviderWrapper>
          <AppLayout />
        </ProviderWrapper>
      </AppInsightsContext.Provider>
    </ThemeProvider>
  );
};
