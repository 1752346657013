import axios from "axios";
import { REPORT_CORE_API_ENDPOINT } from "../../Constants/ApiUrls";
import { IDocwareUser } from "../../Models/IDocumentChapter";
import { concatUrl } from "../../Utils/Utils";

// TODO MR CR-12-2022

const UserController = {
  /** esempio di chiamata GET */
  getUsers: async (text: string, callback?: (response: IDocwareUser[]) => void) => {
    axios.get(
      concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_USER_SUGGESTIONS),
      { withCredentials: true }).then((response) => {
        console.log(response);
        const res: IDocwareUser[] = response.data.map(r => {
          return {
            id: r.id,
            fullName: r.fullname
          } as IDocwareUser
        })
        const filteredRes = res.filter(user => {
          return user?.fullName?.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });

        if (callback) {
          callback(filteredRes);
        }
        return filteredRes;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getUser: async (callback?: (response: IDocwareUser) => void) => {
      axios.get(
        concatUrl(process.env.REPORT_CORE_API_URL, REPORT_CORE_API_ENDPOINT.GET_USER),
        { withCredentials: true }).then((response) => {
            callback?.(response.data)
          return response.data;
    }); 
  }
}

export default UserController;
